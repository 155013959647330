@import '~styles/theme/index';

.#{$wf__ns}leasing-equipment {
  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.fuze-ids-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.fuze-ids-table-open {
        max-height: 24vh;
      }
    }
  }

  .top-row,
  .notes-row {
    display: flex;
    justify-content: space-between;

    .prov-leasing-equipment-section {
      width: 49.5%;
    }

    .prov-leasing-equipment__notes {
      width: 49.5%;
    }
  }

  .bottom-row__col {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__handler {
    margin-bottom: 14px;

    .prov-primary-button:last-of-type {
      margin-left: 15px;
    }
  }

  &-section {
    display: flex;
    flex-direction: column;

    .prov-custom-table__grid .ant-table-body td {
      min-height: 72px;
      height: 100%;
    }

    .ant-collapse-item {
      height: 100%;

      .ant-collapse-content {
        padding: 14px;

        &-box {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }

    &__add-button {
      .ant-btn {
        margin-top: 10px;
        max-width: 100px;
      }
    }
  }

  &__notes {
    padding-top: 14px;

    &-textarea.ant-form-item {
      margin: 15px 0;
      padding: 0;
    }
  }
}
