@import '~styles/theme/index';

.#{$wf__ns}scoping-summary-tab {
  .prov-project-fuze-id {
    margin-bottom: 20px;

    .controls {
      display: flex;
      justify-content: flex-end;

      .ant-btn:first-of-type {
        display: none;
      }
    }
  }
}
