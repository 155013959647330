@import '~styles/theme/index';

.#{$wf__ns}sof-tool-details {
  .scrolling-wrap {
    overflow-y: auto;
    max-height: 68vh;
    margin-top: 25px;
    margin-bottom: 14px;

    &.fuze-ids-table-open {
      max-height: 47vh;
    }
  }
}
