@import '~styles/theme/index';

.#{$wf__ns}project-summary {
  width: $full-res;
  color: $font-color;

  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.fuze-ids-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.fuze-ids-table-open {
        max-height: 30vh;
      }
    }
  }

  &__wrap {
    display: flex;
    margin: 0 0 24px;

    @include dtx {
      flex-direction: column;

      .prov-table-container {
        width: initial;
        margin: 24px 30px 0 0;
      }
    }

    .ant-collapse-header {
      font-weight: 500;
      font-size: 17px;
      line-height: 28px;
    }

    .summary-table {
      min-width: 30vw;

      .ant-table-body {
        max-height: $full-res;

        td {
          padding: 10px;
        }
      }

      .ant-form-item {
        margin: 0;
        padding: 0;
      }

      &__row {
        height: 50px;
      }
    }

    .info-panel {
      min-width: 600px;
      margin-right: 30px;
      flex: 0 0 40%;

      .ant-collapse-content {
        padding: 30px 0 30px 20px;
      }

      .ant-collapse-item.ant-collapse-no-arrow .ant-collapse-header {
        background-color: $blue5;
        padding: 16px 0 16px 30px;
      }

      &__information {
        .ant-form-item {
          margin-bottom: 20px;

          .ant-col-10 {
            max-width: 160px;
          }

          .ant-col-14 {
            max-width: $full-res;
          }

          .ant-select .ant-select-selector {
            height: 40px;

            input {
              height: $full-res;
            }
          }

          .ant-form-horizontal .ant-form-item-label {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .ant-input-textarea {
            height: 95px;
          }
        }
      }

      &__notes {
        .ant-form-item {
          margin: 0;

          .ant-col-10 {
            max-width: 160px;
          }
        }
      }

      > :first-child {
        margin-bottom: 24px;
      }
    }
  }

  &__btns {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;

    .action-wrap {
      display: flex;
      justify-content: space-between;
      width: 332px;
    }

    .danger-btn {
      width: 180px;
    }
  }
}
