@import '~styles/theme/index';

.scoping {
  &-browse {
    .ant-picker {
      height: 40px;
    }

    &__btn {
      position: absolute;
      left: 500px;
    }

    .switch-wrap {
      margin-top: 40px;
    }

    &__schedule-form {
      margin-top: 2em;

      .ant-row.ant-form-item {
        padding-left: 0;
      }

      .ant-form {
        display: flex;
      }
    }

    &__modal {
      .ant-modal-content .ant-modal-footer {
        justify-content: flex-start;
        margin-left: 204px;
      }
    }

    &__form-label .ant-form-item-label {
      margin-right: 18px;
    }
  }
}

.prov-browse-page__wrap .scoping-browse__schedule-form {
  flex-direction: column;
}
