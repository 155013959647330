@import '~styles/theme/index';

.#{$wf__ns}header-bar {
  display: flex;
  justify-content: space-between;
  background: $blue4;
  border-radius: 4px;
  width: $full-res;
  color: $gray2;
  padding: 24px 32px 0 32px;

  &__left,
  &__right {
    display: flex;
    align-items: center;
    min-height: 50px;

    .item,
    .info-box {
      &:not(:last-of-type) {
        margin-right: 50px;
      }
    }
  }

  &__left {
    .item {
      display: inline-flex;
      align-items: center;
      height: 32px;
      line-height: 22px;
      font-weight: 500;
      font-size: $font-size;
      color: $font-color;

      &__label {
        margin-right: 10px;
      }

      &__value,
      &__element {
        font-size: 14px;
        color: $white;
      }

      .project-fuze-id {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $primary;
        cursor: pointer;
        user-select: none;

        &--active {
          background-color: $blue7;
        }
      }
    }
  }

  &__right {
    &.margin {
      margin-bottom: 24px;
    }

    .info-box {
      display: flex;
      align-items: baseline;
      max-width: 150px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      padding: 5px 12px;
      margin-right: 20px;

      &--default {
        background: $yellow-transp;
        border: 1px solid $yellow;

        .info-box__circle {
          background-color: $yellow;
        }

        .info-box__data .title {
          color: $yellow;
        }
      }

      &--inactive {
        background: $red-transp;
        border: 1px solid $red1;

        .info-box__circle {
          background-color: $red1;
        }

        .info-box__data .title {
          color: $red1;
        }
      }

      &--hold {
        background: $orange-transp;
        border: 1px solid $orange;

        .info-box__circle {
          background-color: $orange;
        }

        .info-box__data .title {
          color: $orange;
        }
      }

      &__circle {
        display: inline-block;
        min-width: 10px;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-radius: 50%;
      }

      &__data {
        display: flex;
        flex-direction: column;

        .value {
          color: $white;
          word-break: break-word;
        }
      }
    }

    .direct-message-btn {
      align-self: center;
      margin-left: 30px;
      background: $primary;
      color: $white;

      > .anticon {
        font-size: 30px;
      }

      &:focus,
      &:active {
        background: $primary;
        color: $white;
      }

      &:hover {
        background: $hover-primary;
        color: $white;
      }
    }

    &__dropdown-menu {
      .ant-dropdown-menu {
        background-color: $blue4;

        &-item {
          color: $secondary;
          &:hover {
            background-color: $blue11;
          }
        }
      }
    }
  }
}
