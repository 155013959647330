@import '~styles/theme/index';

.#{$wf__ns}project-management-summary {
  width: $full-res;

  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.fuze-ids-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.fuze-ids-table-open {
        max-height: 24vh;
      }
    }
  }

  &__wrap {
    display: flex;

    @include dtx {
      flex-direction: column;

      .prov-table-container {
        width: initial;
        margin: 24px 30px 0 0;
      }
    }

    .summary-table {
      min-width: 30vw;

      .ant-table-body {
        max-height: $full-res;

        td {
          padding: 10px;
        }
      }

      .ant-form-item {
        margin: 0;
        padding: 0;
      }

      &__row {
        height: 50px;
      }
    }

    .info-panel {
      min-width: 600px;
      margin-right: 30px;

      @include min-dtx {
        width: 65%;
      }

      .ant-collapse-item.ant-collapse-no-arrow .ant-collapse-header {
        padding: 16px 0 16px 30px;
      }

      &__information {
        .ant-form-item {
          margin-bottom: 10px;

          &-label > label {
            height: 22px;
          }

          &-control-input {
            min-height: 22px;
          }

          .ant-col-10 {
            max-width: 150px;
          }

          .ant-col-14 {
            max-width: $full-res;
          }

          .ant-select .ant-select-selector {
            height: 40px;

            input {
              height: $full-res;
            }
          }

          .ant-form-horizontal .ant-form-item-label {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .ant-input-textarea {
            height: 95px;
          }
        }
      }

      &__notes {
        .ant-form-item {
          margin: 0;

          .ant-col-10 {
            max-width: 135px;
          }
        }
      }

      > div {
        margin-bottom: 24px;
      }
    }
  }

  .notes-panel__information,
  .info-panel__information {
    .ant-collapse-content {
      padding: 30px 0;
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .action-wrap {
      display: flex;
      justify-content: space-between;
      width: 332px;
    }

    .danger-btn {
      width: 180px;
    }
  }
}
