@import '~styles/theme/index';

.#{$wf__ns}permitting-workflow {
  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.fuze-ids-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.fuze-ids-table-open {
        max-height: 36vh;
      }
    }

    .ant-collapse .ant-collapse-item .ant-collapse-content {
      height: 100%;
    }
  }

  &__info-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    margin-bottom: 25px;

    .prov-view-element {
      margin: 0 0 15px;
    }

    .#{$wf__ns}table-container {
      height: 100%;

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 58px);

        .information {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;

          .left {
            grid-column: 1 / 2;
          }

          .right {
            grid-column: 2 / 3;
          }
        }

        .action-button {
          max-width: 150px;
        }

        .drop-down {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 150px;
          height: 40px;
          border-radius: 5px;
          background-color: $primary;
          color: $white;
          margin-top: 30px;

          &:hover {
            background-color: $hover-primary;
            cursor: pointer;
          }

          &.active {
            background-color: $blue10;
          }

          &__context {
            position: relative;

            .icon {
              position: absolute;
              right: 5px;
              color: $blue12;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  &__actions {
    margin-top: 30px;

    > *:not(:last-of-type) {
      margin-right: 15px;
    }
  }
}

.leasing-and-engineering-drop-down-menu .ant-dropdown-menu {
  background-color: $blue3;
  border-radius: 7px;

  &-item {
    &:hover {
      background-color: $blue11;
    }

    &:hover a,
    a {
      color: $white;
    }
  }
}
