@import '~styles/theme/index';

.#{$wf__ns}project-create {
  width: $full-res;

  > :not(:last-child) {
    margin-bottom: 2em;
  }

  &__action-buttons > :first-child {
    margin-right: 10px;
  }

  .tabs-wrap > .ant-collapse > .ant-collapse-item .ant-collapse-content {
    height: calc(100vh - 240px);

    &_with-actions > .ant-collapse > .ant-collapse-item .ant-collapse-content {
      max-height: $full-res;
    }
  }

  &__form {
    .ant-collapse {
      &-content > .ant-collapse-content-box {
        padding: 30px 0;
      }

      .ant-collapse-item > div.ant-collapse-header {
        background: $blue5;
        font-weight: 500;
        font-size: 17px;
        line-height: 28px;
        color: $white;
        padding-left: 32px;
      }
    }
  }

  .prov-project-fuze-id {
    margin-top: 24px;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
  }

  .fuze-ids-error {
    color: $red1;
  }
}
