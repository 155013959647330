.site-audit-details {
  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.fuze-ids-table-open {
      max-height: 66vh;
    }

    &_with-actions {
      max-height: 60vh;

      &.fuze-ids-table-open {
        max-height: 61vh;
      }
    }
  }

  &-header {
    margin-bottom: 24px;
  }
}
